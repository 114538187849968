<template>
    <v-container>
        <p class="text-h6 white--text font-weight-bold">จัดการคำสั่งซื้อ</p>
        <div class="d-flex justify-space-between">
            <div style="width: 300px">
                <v-text-field dark dense label="ค้นหารายการ" solo prepend-inner-icon="mdi-magnify"
                    @keyup.enter="refreshData" v-model="textSearch">
                </v-text-field>
            </div>

            <div class="mt-n4">
                <div class="d-inline-flex">
                    <label class="grey--text text-body1">วันที่ทำการสั่งซื้อ</label>
                </div>
                <div class="d-inline-flex ml-4" style="width: 400px;">
                    <v-menu dark ref="menu" v-model="menu" :close-on-content-click="false" :close-on-click="false"
                        transition="scale-transition" offset-y min-width="auto" :return-value.sync="dates">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field dark v-model="datesText" prepend-inner-icon="mdi-calendar" readonly
                                v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="dates" no-title scrollable range color="grey darken-1">
                            <v-spacer></v-spacer>
                            <v-btn text color="grey" @click="menu = false">
                                Cancel
                            </v-btn>
                            <v-btn text color="primary" @click="filterDateRange">
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                    <v-btn v-if="currentRole == 'staff'" @click="$router.push({ name: 'JobsCreate'})" class="mt-3 ml-3"
                        outlined color="yellow darken-3">
                        <v-icon>
                            mdi-plus
                        </v-icon>
                        คำสั่งซื้อใหม่
                    </v-btn>
                </div>

            </div>
        </div>
        <v-divider dark class="mb-5"></v-divider>
        <div class="mb-5">
            <v-chip-group dark mandatory active-class="primary--text" @change="showDataTable" v-model="statusFilter">
                <v-chip v-for="filter of filters" :key="filter.value" :value="filter.value">
                    {{ filter.name }}
                </v-chip>
            </v-chip-group>
        </div>

        <v-data-table dark v-model="selected" :headers="headers" :items-per-page="30" :items="dataForShow"
            :loading="isLoading" loading-text="กำลังโหลดข้อมูล....... กรูณารอซักครู่" class="elevation-1"
            style="width: 100%;border: 0.5px solid #aaaaaa;" show-select no-data-text="ไม่มีข้อมูล"
            @click:row="clickRow">
            <template v-slot:top>
                <div class="d-flex pa-3">
                    <div class="mr-auto">
                        <p class="text-h6 ma-2">คำสั่งซื้อทั้งหมด
                            <span class="yellow--text text--darken-3"> {{
                                String(dataForShow.length).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} รายการ </span>
                        </p>
                    </div>
                    <div class="px-3">
                        <v-btn plain :disabled="0 == selected.length" @click="downloadCSV">
                            <v-icon right dark class="mr-2">
                                mdi-cloud-download
                            </v-icon>
                            ดาวน์โหลด
                        </v-btn>
                    </div>
                </div>
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
                {{ formatTimeTable(item.createdAt) }}
            </template>
            <template v-slot:[`item.channel`]="{ item }">
                {{ getChannelName(item.channel) }}
            </template>
            <template v-slot:[`item.totalPrice`]="{ item }">
                <label class="yellow--text text--darken-3">
                    {{ Number(item.totalPrice).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
                </label>
            </template>
            <template v-slot:[`item.status`]="{item}">
                <v-chip small :color="colorStatus(item.status)">
                    <v-icon class="ml-n3" :color="colorStatus(item.status).split(' ')[0]">mdi-circle-medium</v-icon>
                    <span class="">{{ getStatusThaiName(item.status) }}</span>
                </v-chip>
            </template>
            <template v-slot:[`item.action`]="{item}">
                <v-container class="text-right">

                    <v-btn v-if="item.status == 'wait_messenger' && currentUser.role != 'account'"
                        color="yellow darken-3" depressed @click.stop.prevent="downloadCover(item)">
                        <span class="black--text">
                            พิมพ์ใบแปะหน้า
                        </span>
                    </v-btn>

                    <!-- Staff Action -->
                    <v-btn color="yellow darken-3" depressed
                        v-if="currentUser != null && currentUser.role == 'staff' && item.status == 'new'"
                        @click.stop.prevent="$router.push({ name: 'JobsDetails', params: { id: item.id} })">
                        <span class="black--text">
                            ส่งขออนุมัติ
                        </span>
                    </v-btn>

                    <!-- Sr Staff Action -->
                    <v-btn color="yellow darken-3" depressed
                        v-if="currentUser != null && currentUser.role == 'sr_staff' && item.status == 'wait_confirm'"
                        @click.stop.prevent="$router.push({ name: 'JobsDetails', params: { id: item.id} })">
                        <span class="black--text">
                            อนุมัติ
                        </span>
                    </v-btn>
                </v-container>
            </template>
        </v-data-table>
        <alert-error :text="errorMessage" ref="alertError"></alert-error>
    </v-container>
</template>

<script>
import moment from 'moment';
import { getJobsList, getChannelName, getStatusThaiName, downloadCover, colorStatus } from '../../models/job';
import { getProfile } from '../../models/user';
import AlertError from '../../components/AlertError.vue';
import axios from 'axios';
import fd from 'js-file-download';

export default {
    data: ()=>({
        dateFormatApi: 'DD-MM-YYYY',
        textSearch: '',
        startDate: null,
        endDate: null,
        dates: [],
        datesText: '',
        menu: false,
        selected: [],
        isLoading: false,
        headers: [
            { text: 'หมายเลขคำสั่งซื้อ', value: 'code', sortable: false },
            { text: 'สร้างวันที่', value: 'createdAt' },
            { text: 'ชื่อลูกค้า', value: 'customerName', sortable: false },
            { text: 'มูลค่าสินค้า', value: 'totalPrice' },
            { text: 'สถานะรายการ', value: 'status' },
            { text: 'ช่องทาง', value: 'channel' },
            { text: '', value: 'action', sortable: false }
        ],
        data:[],
        dataForShow: [],
        errorMessage: '',
        filters: [
            { name: 'ทั้งหมด', value: 0 },
            { name: 'มาใหม่', value: 7 }, // ** because requirement add lastest
            { name: 'ส่งคำขออนุมัติ', value: 1 },
            { name: 'รอการอนุมัติ', value: 2 },
            { name: 'ที่ต้องจัดส่ง', value: 3 },
            { name: 'กำลังจัดส่ง', value: 4 },
            { name: 'จัดส่งสำเร็จ', value: 5 },
            { name: 'ยกเลิก/คืนเงิน', value: 6 }
        ],
        statusFilter: 0,
        currentUser: null
    }),
    methods: {
        clickRow(e){
            this.$router.push({ name: 'JobsDetails', params: { id: e.id } });
        },
        filterDateRange(){
            this.$refs.menu.save(this.dates);
            this.refreshData();
        },
        async fetchCurrentUser(){
            try{
                const response = await getProfile();
                this.currentUser = response.data.user;
            }catch(error){
                console.log(error);
                this.errorMessage = error.response?.data[0]?.msg || error.response?.data || error.message;
                this.$refs.alertError.show();
            }
        },
        async refreshData(){
            this.$router.push({ 
                name: 'JobsList', 
                query : { 
                    textSearch: this.textSearch, 
                    startDate: this.startDate || '', 
                    endDate: this.endDate || '' } 
            }).catch(() => {});
            this.isLoading = true;
            this.data = [];
            try{
                const response = await getJobsList(true, this.textSearch, this.startDate || '', this.endDate || '');
                const jobs = response.data.jobs;
                jobs.forEach(j => {
                    this.data.push(j);
                });
                this.showDataTable();
            }catch(error) {
                console.log(error);
                this.errorMessage = error.response?.data[0]?.msg || error.response?.data || error.message;
                this.$refs.alertError.show();
            }finally{
                this.isLoading = false;
            }
        },
        getChannelName(value) {
            return getChannelName(value);
        },
        formatTimeTable(date){
            return moment(date).format('DD-MM-YY HH:mm');
        },
        showDataTable(){ 
            if(0 == this.statusFilter){ // all
                this.dataForShow = this.data;
            }else if(1 == this.statusFilter){ // ส่งคำขออนุมัติ
                this.dataForShow = this.data.filter(d => 'new' == d.status);
            }else if(2 == this.statusFilter){ // รออนุมัติ
                this.dataForShow = this.data.filter(d => 'wait_confirm' == d.status || 'wait_confirm_with_approval' == d.status);
            }else if(3 == this.statusFilter){ // ที่ต้องจัดส่ง
                this.dataForShow = this.data.filter(d => 'wait_messenger' == d.status);
            }else if(4 == this.statusFilter){ // กำลังส่ง
                this.dataForShow = this.data.filter(d => 'sending' == d.status);
            }else if(5 == this.statusFilter){ // ส่งแล้ว
                this.dataForShow = this.data.filter(d => 'sent' == d.status);
            }else if(6 == this.statusFilter){ // ยกเลิก
                this.dataForShow = this.data.filter(d => 'cancel' == d.status);
            }else if(7 == this.statusFilter){ // มาใหม่
                this.dataForShow = this.data.filter(d => 'before_new' == d.status);
            }
        },
        getStatusThaiName(value){
            return getStatusThaiName(value);
        },
        colorStatus,
        downloadCover: downloadCover,
        downloadCSV(){
            axios.post(process.env.VUE_APP_BASE_URL + '/reports/job', { jobs: this.selected.map(j => j.id) }, { responseType: 'blob' })
                .then(response => {
                    fd(response.data, 'job.csv', response.headers['content-type']);
                });
        }
    },
    computed:{
        currentRole() {
            return this.currentUser ? this.currentUser.role : '';
        }
    },
    mounted(){
        this.fetchCurrentUser();
        this.textSearch = this.$route.query.textSearch || '';
        this.startDate = this.$route.query.startDate || null;
        this.endDate = this.$route.query.endDate || null;
        this.refreshData();
    },
    components: {
        AlertError
    },
    watch: {
        dates(){
            if(1 == this.dates.length){
                this.datesText = moment(this.dates[0]).format('DD/MM/YYYY');
                this.startDate = null;
                this.endDate = null;
            }else if(2 == this.dates.length){
                const d1 = moment(this.dates[0]);
                const d2 = moment(this.dates[1]);
                if(0 < d2.diff(d1)){
                    this.datesText = d1.format('DD/MM/YYYY') + ' - ' + d2.format('DD/MM/YYYY');
                    this.startDate = d1.format(this.dateFormatApi);
                    this.endDate = d2.format(this.dateFormatApi);
                }else{
                    this.datesText = d2.format('DD/MM/YYYY') + ' - ' + d1.format('DD/MM/YYYY');
                    this.startDate = d2.format(this.dateFormatApi);
                    this.endDate = d1.format(this.dateFormatApi);
                }
            }
        },
        textSearch(){
        }
    }
};
</script>